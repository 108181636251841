@import "../../../variables.scss";

.solution {
    background: $appBackground;
    padding-top: $topBarHeight;
    display: grid;
    justify-items: center;

    .dotted-random-pattern {
        position: absolute;
        left: -300px;
        width: 300px;
        bottom: 0;
        opacity: 0.95;
        z-index: 0;
    }

    .topDescription {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: $s2 $s10;
        text-align: left;
        position: relative;
        max-width: calc($s1 * 200);
        min-height: calc($s1 * 50);


        h2 {
            width: 600px;
            max-width: 90vw;
        }

        section.left {

            .btn {
                margin-top: $s5;
                background-color: lighten($color: $tertiaryColorLighten, $amount: 0.1);
                min-width: calc($s1 * 35);
            }
        }

        .heroImage {
            position: absolute;
            bottom: -$s1;
            right: 10%;
            border-radius: $s1;
            max-height: calc($s1 * 60);
        }

        .collaboratorLogo {
            background-color: $tertiaryColorWhiteOffSet;
            max-width: calc($s1 * 30);
            padding: $s2;
            border-radius: $s2;
        }


    }


    .solutionBox {
        padding: $s2 $s10;
        background-color: $panelColour;
        z-index: 1;
        position: relative;
        min-height: 75vh;
        width: 100%;
        display: grid;
        justify-items: center;

        .contentBox {
            max-width: calc($s1 * 200);
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: $s5;
            justify-items: center;
            align-items: center;
            padding: $s2 $s10;

            section.left {
                text-align: left;


                .featuresBox {
                    padding: $s2 0;

                    .feature {
                        display: flex;
                        gap: $s2;
                        align-items: start;
                    }
                }
            }



            p {
                max-width: 800px;
                margin: 25px 0;
            }



            section.right {
                position: relative;

                .heroImage {
                    margin: 0 0 25px 0;
                    width: 800px;
                    max-width: 100%;
                    border-radius: $s2;
                    border: none;
                    outline: none;
                    z-index: 1;
                }

                .dotted-pattern {
                    position: absolute;
                    right: 0;
                    top: 40%;
                    transform: scale(1.5);
                }
            }

        }


    }


    .benefitsBox {
        min-height: 75vh;
        width: 100%;
        background-color: $appBackgroundColorOffWhite;
        display: grid;
        justify-items: center;
        align-content: center;

        .subtitle2 {
            max-width: fit-content;
        }

        .contentBox {
            max-width: calc($s1 * 200);
            display: flex;
            flex-wrap: wrap;
            padding: $s2 $s10;
            gap: $s2;

            .benefit {
                width: calc($s1 * 40);
                flex: 1 auto;
                max-width: 90vw;
                padding: $s2;
                text-align: left;
                background-color: $appBackgroundTextColor;
                max-height: calc($s1 * 50);
                transition: all 0.25s;
                border: 1px solid rgba(0, 0, 0, 0);
                border-radius: calc(#{$s1}/2);

                &:hover {
                    transform: translateY(-$s1);
                    cursor: pointer;
                    border: 1px solid $tertiaryColorOffset;
                }
            }
        }
    }

}

@media only screen and (max-width: 1120px) {
    .solution {

        .topDescription {
            padding: $s2;

            *{
                z-index: 1;
            }

            img {
                max-width: 50vw;
                z-index: 0;
            }
        }

        .solutionBox {
            padding: $s2;

            .contentBox {
                padding: $s4 0;
                grid-template-columns: 1fr;
                gap: $s6;
            }



            section.left {
                text-align: center;

            }
        }

        .benefitsBox {
            .contentBox {
                padding: $s2;
            }
        }
    }
}