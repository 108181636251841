@import "../../variables.scss";

/* Tooltip container */
.tooltip {
    position: relative;
    display: grid;
    align-items: center;
    color: $tertiaryColor;

    &:hover {
        cursor: pointer;
    }
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    max-width: calc(#{$s1} * 20);
    background-color: $tertiaryColor;
    color: #fff;
    text-align: center;
    padding: $s1;
    border-radius: 6px;
    margin-left: $s1;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.partnerLevels {
    display: grid;
    justify-content: center;
    align-content: center;
    color: $panelTextColour;
    padding-top: $topBarHeight;
    row-gap: $s5;

    h1 {
        color: $tertiaryColor;
        margin: 0;
        margin-top: $s10;
    }

    .container {
        width: 1450px;
        max-width: 99vw;
        padding: 0 0 $s5 0;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5px;
        color: $panelTextColour;
        z-index: 2;
        display: grid;
        row-gap: $s2;
        text-align: left;
        position: relative;
        margin-top: $s5;

        .boxes {
            position: absolute;
            top: -50px;
            height: 100%;
            width: 100%;
            left: 20%;
            display: flex;
            gap: 1%;
            span {
                height: calc(100% + 50px);
                width: 19%;
                border: 1px solid adjust-color($color: $tertiaryColor, $alpha: -0.85);
                display: inline-block;
                border-radius: 5px;
            }
        }

        .headerSection {
            padding: 0 $s2;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            column-gap: $s4;
            row-gap: $s1;
            background-color: $tertiaryColor;
            border-radius: $s1;
            color: $primaryTextColor;
            font-family: $headerFont;
            font-size: $subtitle2Large;
        }

        .bodySection {
            display: grid;
            row-gap: $s10;

            .featureGroup {
                display: grid;
                row-gap: $s1;
                padding: $s1 0;

                h3 {
                    color: $tertiaryColor;
                    margin: $s1 0;
                    padding: 0 $s2;
                }

                .featureContainer {
                    display: grid;
                    row-gap: $s1;

                    .feature {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        column-gap: $s4;
                        padding: $s1;
                        border-radius: $s1;
                        align-items: center;
                        padding: 0 $s2;
                        height: max-content;
                        min-height: calc(#{$s1} * 12);

                        .featureTitle {
                            display: flex;
                            gap: $s1;
                        }

                        &.alternate {
                            background-color: adjust-color($color: $tertiaryColor, $alpha: -0.95);
                        }

                        .tick {
                            color: $tertiaryColorLighten;
                            font-size: $headingLarge;
                            display: grid;
                            align-items: center;
                        }

                        .times {
                            color: adjust-color($color: $tertiaryColor, $alpha: -0.55);
                            font-size: $headingLarge;
                            display: grid;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }

    .containerSmall {
        display: none;
        justify-content: center;
        align-items: center;
        text-align: left;
        row-gap: $s5;

        .headerSection {
            padding: 0 $s2;
            display: grid;
            grid-template-columns: 1fr 0.5fr;
            column-gap: $s4;
            row-gap: $s1;
            background-color: $tertiaryColor;
            border-radius: $s1;
            color: $primaryTextColor;
            font-family: $headerFont;
            font-size: $subtitle2Large;
        }

        .bodySectionSmall {
            display: grid;
            row-gap: $s2;

            .featureGroup {
                display: grid;
                row-gap: $s1;
                padding: $s1 0;

                &>h3 {
                    padding: 0 $s2;
                }

                .featureContainer {
                    display: grid;
                    row-gap: $s1;

                    .feature {
                        display: grid;
                        grid-template-columns: 1fr 0.5fr;
                        column-gap: $s4;
                        height: max-content;
                        min-height: calc(#{$s1} * 8);
                        border-radius: $s1;
                        align-items: center;
                        padding: 0 $s2;

                        &.alternate {
                            background-color: adjust-color($color: $tertiaryColor, $alpha: -0.95);
                        }

                        .tick {
                            color: $tertiaryColorLighten;
                            font-size: $headingLarge;
                            display: grid;
                            align-items: center;
                        }

                        .times {
                            color: adjust-color($color: $tertiaryColor, $alpha: -0.55);
                            font-size: $headingLarge;
                            display: grid;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .partnerLevels {
        padding-left: $s1;
        padding-right: $s1;

        .container {
            display: none;
        }

        .containerSmall {
            display: grid;
        }
    }
}

@media only screen and (max-width: 550px) {
    .partnerLevels {
        padding-left: $s1;
        padding-right: $s1;

        .container {
            display: none;
        }

        .containerSmall {
            display: grid;

            .feature {
                span {
                    font-size: $subinfoLarge !important;
                }
            }
        }
    }
}