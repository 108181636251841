@import '../../variables.scss';

.Solutions {
  display: grid;
  align-content: start;
  min-height: 80vh;
  color: $panelTextColour;
  justify-items: center;
  position: relative;
  padding-top: $topBarHeight;
  background: $appBackground;

  .dotted-random-pattern {
    position: absolute;
    left: -300px;
    width: 300px;
    bottom: 0;
    opacity: 0.95;
    z-index: 0;
  }

  .topDescription {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: $s2 $s10;
    text-align: left;
    position: relative;



    h2 {
      width: 600px;
      max-width: 90vw;
    }

    section.left {

      .statsContainer {
        display: flex;
        justify-content: space-evenly;
        gap: $s3;
        text-align: center;
        background-color: lighten($color: $tertiaryColorLighten, $amount: 0.1);
        border-radius: $s1;
        padding: $s1;
        max-width: 90vw;

        h1 {
          margin: 0;
          display: flex;
          justify-content: center;
        }

        .stats {
          border-right: 1px solid $primaryTextColor;
          padding: $s3;

          &:last-child {
            border: none;
          }
        }

      }

      .btn {
        margin-top: $s5;
        background-color: lighten($color: $tertiaryColorLighten, $amount: 0.1);
        min-width: calc($s1 * 35);
      }
    }

    .heroImage {
      position: absolute;
      bottom: -$s6;
      right: 0;
      width: $s1 * 50;
      max-width: 90vw;
    }
  }

  & * {
    z-index: 1;
  }



  &>p {
    max-width: 800px;
  }

  & * {
    display: block;
  }


  .exampleSection {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    grid-column-gap: 24px;
    grid-row-gap: 4rem;
    margin-top: 50px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    .usageExample {
      display: grid;
      grid-template-columns: 1fr;
      max-width: 350px;
      text-align: left;
      background-color: $panelColour;
      color: black;
      border-radius: 5px;
      margin-top: 50px;
      overflow: hidden;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);

      .lottieContainer {
        height: 200px;
        background-size: cover;
      }

      .descriptionSection {
        padding: $s2;

        p {
          z-index: 2;
        }

        .navigationLink {
          span {
            display: flex;
            gap: $s1;
            align-items: center;
            text-decoration: underline;
          }
        }

        .FilledPattern {
          transition: all 0.25s;
        }

        &:hover {
          .FilledPattern {
            right: 25%;
          }
        }


      }

      .solutionHeader {
        display: grid;
        grid-template-columns: auto 1fr;
        justify-content: center;
        align-items: center;
        grid-column-gap: 1rem;

        svg {
          height: 44px;
          width: 44px;
        }
      }

      .description {
        min-height: 130px;
      }



    }

  }

  .comingSoonSection {

    margin-top: 50px;
    padding: 0 25px 75px 25px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
    justify-self: stretch;
    background-color: $panelColour;

    display: grid;
    justify-items: center;

    & h1 {
      color: $panelTextColour;
    }

    h2 {
      color: $panelTextColour;
      width: 800px;
      max-width: 90vw;
    }

    .comingSoonHeading {
      margin-top: 75px;

    }


    .comingSoonexampleSection {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
      grid-column-gap: 24px;
      grid-row-gap: $s4;
      margin-top: 50px;
      justify-self: start;
      width: 100%;
      padding: $s2;
      width: 1200px;
      max-width: 90vw;
      margin-left: auto;
      margin-right: auto;


      .usageExample {

        border-radius: calc(#{$s1}/2);
        box-shadow: 0 0 calc(#{$s1}/3) rgba(0, 0, 0, 0.15);
        overflow: hidden;
        background-color: adjust-color($color: $primaryColor, $red: 200, $green: 200, $blue: 255, $alpha: 1.0);
        transition: all 0.25s;
        border: 1px solid rgba(0, 0, 0, 0);

        &:hover {
          transform: translateY(calc($s1/2));
          border: 1px solid $tertiaryColorOffset;
          background-color: $appBackgroundColorOffWhite;
        }

        .headerImage {
          height: 250px;
          background-size: cover;
        }

        .descriptionSection {
          display: grid;
          column-gap: 16px;
          text-align: left;
          padding: $s4;

          .headerSmall {
            display: flex;
            gap: $s2;
            font-weight: bold;
          }

          .btn {
            height: unset !important;
          }

          .navigationLink {

            max-width: fit-content;

            
            span {
              display: flex;
              gap: $s1;
              align-items: center;
              text-decoration: underline;

              &:hover {
                cursor: pointer;
              }

              svg {
                height: $s2;
                width: $s2;
              }
            }
          }
        }

        .description {
          min-height: 130px;
        }


        svg {
          height: 44px;
          width: 44px;
        }
      }

    }
  }




}

@media only screen and (max-width: 1100px) {
  .Solutions {
    .topDescription {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;

      padding: $s2;

      section.left {
        .statsContainer {
          .stats {
            padding: $s1;
          }
        }
      }

      .heroImage {
        position: relative;
        bottom: 0;
        right: 0;
        width: $s1 * 50;
      }
    }

    .comingSoonSection {

      padding: $s2;

      .comingSoonexampleSection {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        padding: 0;

        .usageExample {

          .descriptionSection {
            padding: $s2;
          }
        }
      }
    }
  }
}



// @media only screen and (max-width: 720px) {
//   .Solutions {
//     .exampleSection {
//       grid-template-columns: 1fr;
//     }
//   }
// }