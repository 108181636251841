/* variables */
$primaryColor: #e24522;
$primaryColorGradientCompliment: #FE8501;
$primaryColorFaded: #fda03c;
// $primaryColorFaded: #ffbd76;

$secondaryColor: #3495D6;
$secondaryColorOffset: #97d5ff;
$tertiaryColor: #343e8e;
$tertiaryColorLighten:  #4453be;
$tertiaryColorWhiteOffSet: #EAE9FF;
$tertiaryColorDarkOffSet: #273070;

$tertiaryColorDarkTextColor: #292b3d;



$tertiaryColorOffset: #e3e6ff;

$appBackgroundColor: $tertiaryColor;
$appBackground: radial-gradient(55% 55%, #343e8e, #2e3a99);
$appBackgroundColorOffWhite: #f8feff;
$appBackgroundColorOffWhiteTransparent: #c2c2c26b;

$alternateBackgroundColor: #f9fafb;

$primaryTextColor: rgb(31, 31, 31);
$secondaryTextColor: #6f7f96;
$appBackgroundTextColor: rgb(255, 255, 255);
$topBarHeight: 100px;


$panelColour: $alternateBackgroundColor;
$panelTextColour:darken($color: $panelColour, $amount: 90);
$panelSecondaryTextColour:darken($color: $panelColour, $amount: 80);



@font-face {
    font-family: bodyFont;
    src:url("/fonts/THICCCBOI-Medium.ttf");
}

//font variables
$headerFont: 'Montserrat', sans-serif;
$bodyFont: bodyFont;

$bannerFontSize: 67.34px;
// font size variables
$bigHeadingLarge: 48px;
$bigHeadingMedium: 32px;
$bigHeadingSmall: 32px;

$headingLarge: 32px;
$headingMedium: 24px;
$headingSmall: 22px;

$subtitle1Large: 24px;
$subtitle1Medium: 18px;
$subtitle1Small: 16px;

$subtitle2Large: 20px;
$subtitle2Medium: 16px;
$subtitle2Small: 16px;

$bodyLarge: 18px;
$bodyMedium: 16px;
$bodySmall: 16px;

$subinfoLarge: 14px;
$subinfoMedium: 12px;
$subinfoSmall: 10px;






//testimonial stylings
$testimonialBackGround: #fff;








//side bar styles
$sideBarWidth: 350px;





$s1: 8px;
$s2: 16px;
$s3: 24px;
$s4: 32px;
$s5: 40px;
$s6: 48px;
$s7: 56px;
$s8: 64px;
$s9: 72px;
$s10: 80px;

:export{
    primaryColor: $primaryColor;
    appBackgroundColorOffWhite: $appBackgroundColorOffWhite;
    panelColor: $panelColour;
}
