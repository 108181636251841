@import '../../variables.scss';

.whatOthersSay {
  min-height: 250px;
  display: flex;
  flex-wrap: wrap;
  background-color: $appBackgroundColorOffWhite;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: $s8;

  .testimonial {
    width: 300px;
    background-color: $testimonialBackGround;
    border-radius: 5px;
    margin-right: 20px;
    display: grid;
    align-items: center;

    img{
      max-height: 200px;
    }
  }

  .testimonial2 {
    min-height: 150px;
    width: 350px;
    background-color: $testimonialBackGround;
    border-radius: 5px;
    margin-right: 40px;
    display: grid;
    align-items: center;
    margin-top: 10px;
  }

  .partner {
    height: 80px;
    filter: grayscale(1);

    &:hover{
      filter: unset;
    }
  }
}

@media only screen and (max-width: 968px) {
  .whatOthersSay {
    .testimonial {
      min-height: 100px;
      width: 300px;
    }

    .testimonial2 {
      min-height: 100px;
      width: 300px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .whatOthersSay {
    display: none;
    .testimonial {
      min-height: 75px;
      width: 200px;
    }

    .testimonial2 {
      min-height: 75px;
      width: 200px;
    }

    .partner {
      height: 40px;
    }
  }
}
