@import "../../variables.scss";

.topBar {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  height: $topBarHeight;
  position: sticky;
  top: 0;
  z-index: 9999;

  path{
    fill: white !important;
  }


  .leftSection {
    width: 100%;
    display: grid;
    justify-items: start;
    align-items: center;
    width: 100%;

    img {
      height: 44px;
    }
  }

  .rightSection {
    display: grid;
    align-items: center;
    justify-self: end;
    width: 90%;
    max-width: 750px;

    ul {
      list-style: none;
      display: flex;
      width: 100%;
      justify-content: space-around;
      margin: 0;

      a {
        text-decoration: none;
        font-weight: bold;

        &:hover {
          color: $primaryColor;
        }
      }
    }
  }

  .burgerMenuButton {
    position: relative;
    z-index: 1010;
    animation-name: topBarAnimation;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-delay: 2.5s;
    justify-self: end;

    &:hover {
      cursor: pointer;
    }

    display: none;
  }
}

@media only screen and (max-width: 968px) {
  .topBar {
    .burgerMenuButton {
      display: block;
    }

    .rightSection {
      ul {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .topBar {
    height: 60px;

    .leftSection {
      img {
        height: 32px;
      }
    }
  }
}