@import "../../variables.scss";

.kzen8ForIndustryExperts {
  padding: 50px 25px 100px 25px;
  min-height: 50vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  text-align: left;
  background-color: $appBackgroundColorOffWhite !important;

  .leftSection {
    display: grid;
    gap: $s1;
    h1 {
      color: $tertiaryColor;
    }

    .paragraph {
      color: $panelTextColour;
      display: grid;
      gap: $s2;
    }
  }

  .paragraph {
    max-width: 768px;
    width: 100%;
    line-height: $s4;
  }

  a{
    width: fit-content;
  }

  .callToAction {
    width: 250px;
  }

  video {
    box-shadow: 0 0 1px rgba($color: #000000, $alpha: 0.25);
    width: 95%;
  }




}

@media only screen and (max-width: 1450px) {
  .kzen8ForIndustryExperts {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: $s8;
    text-align: center;

    .paragraph{
      line-height: unset;
      justify-items: center;

      ul{
        text-align: left;
      }

      a{
        margin-top: $s2;
      }
    }
  }
}

@media only screen and (max-width: 811px) {
  .kzen8ForIndustryExperts {
    .paragraph {
      text-align: justify;
    }



    // .stepList {
    //     text-align: center;
    //     margin-top: 25px;
    //     padding: 0;
    //     li {
    //         h3 {
    //             margin: 0;
    //         }

    //         .stepImage {
    //         }
    //     }
    // }
  }
}

@media only screen and (max-width: 550px) {
  .kzen8ForIndustryExperts {
    // .stepList {
    //     padding: 0;
    //     li {
    //         h3 {
    //             margin: 0;
    //         }

    //         .stepImage {
    //         }
    //     }
    // }
  }
}
