@import '../../variables.scss';

.platform {
    min-height: 100vh;
    padding-top: $topBarHeight;

    .heroSection {
        min-height: calc(100vh - $topBarHeight);
        display: grid;
        grid-template-columns: 1fr 1fr;
        z-index: 1;
        position: relative;

        .leftSection {
            display: grid;
            align-content: center;
            row-gap: $s2;
            text-align: left;

            h1 {
                margin-bottom: $s3;
            }

            span {
                max-width: calc($s1 * 65);
            }


            .buttonContainer{
                display: flex;
                flex-wrap: wrap;
                column-gap: $s2;
        
                a {
                  width: min(calc($s1 * 37), 80vw);  
                  flex: 1 auto;
                    
                  .callToAction {
                    margin-top: 1rem;
                    width: 100%;
                  }
                }
            }
        }

        .rightSection {
            position: relative;

            .heroImageContainer {
                position: absolute;
                bottom: 0;
                left: -10%;
                width: 110%;
                max-width: 90vw;
                max-height: calc(100vh - $topBarHeight);

                img {
                    max-width: 90vw;
                }

                *[class^='popup'] {
                    position: absolute;
                    width: unset;
                    max-width: 10vw;
                    background-size: contain;
                    border-radius: calc($s1/2);

                    &.integrations {
                        max-width: 64px;
                    }
                }

                .popup1 {
                    top: 40%;
                    right: 10%;
                    animation-delay: 0.5s;
                }

                .popup2 {
                    top: 40%;
                    left: 20%;
                    animation-delay: 0.75s;
                }

                .popup3 {
                    bottom: 20%;
                    right: $s1;
                    animation-delay: 1s;
                }


                .popup4 {
                    bottom: 20%;
                    left: 10%;
                    animation-delay: 0.5s;
                }
            }
        }
    }

    .middlePieceSection{
        display: grid;
        text-align: left;
        grid-template-columns: 1fr 1.15fr;
        padding: $s10 calc($s1 * 20);
        background-color: darken($color: $appBackgroundTextColor, $amount: 3);
        z-index: 2;
        position: relative;

        .leftSection{
            span{
                display: block;
                max-width: min(calc($s1 * 50), 80vw);
            }
        }

        .rightSection{
            display: grid;
            row-gap: $s1;
            .piece{
                display: flex;
                gap: $s2;
                align-items: center;
                justify-content: space-between;
                padding: $s4;
                border-radius: calc($s1/4);
                border: 2px solid $tertiaryColorWhiteOffSet;

                img{
                    height: $s6;
                    object-fit: cover;
                }

                span{
                    display: block;
                    max-width: min(calc($s1 * 50), 80vw);
                }
            }
        }
    }

    .integrationSection {
        background-color: $appBackgroundTextColor;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: $s10 calc($s1 * 20);
        text-align: left;
        row-gap: $s10;
        overflow: hidden;

        .background {
            display: grid;
            justify-content: end;
            height: 100%;
            width: 100%;
            position: absolute;

            .linearBackground {
                background: linear-gradient(to right, $appBackgroundTextColor 30%, transparent);
                position: absolute;
                height: 100%;
                width: 100%;
            }
        }

        .leftSection {
            z-index: 1;
            justify-content: center;

            span {
                display: block;
                max-width: min(calc($s1 * 50), 80vw);
            }
        }

        .rightSection {
            z-index: 1;

            .heroImageContainer {
                position: relative;
                height: 100%;
                width: calc($s1 * 75);
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: calc($s1 * 50);

                .hub {
                    height: calc($s1 * 20);
                    width: calc($s1 * 20);
                    background-color: $appBackgroundTextColor;
                    border: 1px solid $tertiaryColorWhiteOffSet;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;

                    img{
                        max-width: 75%;
                    }
                }

                *[class^='popup'] {
                    position: absolute;
                    width: unset;
                    background-size: contain;
                    border-radius: calc($s1/2);

                    background-color: $appBackgroundTextColor;
                    padding: $s2;
                    border-radius: 100%;

                    &.integrations {
                        width: auto;
                        aspect-ratio: 1/1;
                        height: calc($s1 * 11);
                        transition: all 0.25s !important;

                        &:hover {
                            transform: scale(1.1) !important;
                            cursor: pointer;
                        }
                    }
                }

                .popup4 {
                    top: 10%;
                    right: 10%;
                    animation-delay: 0.5s;
                }

                .popup5 {
                    top: -10%;
                    left: 40%;
                    animation-delay: 0.75s;
                }

                .popup6 {
                    bottom: -10%;
                    right: calc($s1 * 18);
                    animation-delay: 1s;
                }

                .popup7 {
                    bottom: 10%;
                    left: $s10;
                    animation-delay: 1s;
                }


                .popup8 {
                    bottom: 30%;
                    right: $s2;
                    animation-delay: 1s;
                }

                .popup9 {
                    bottom: 40%;
                    left: $s2;
                    animation-delay: 1s;
                }

                .popup10 {
                    bottom: -20%;
                    left: 30%;
                    animation-delay: 1s;
                }


                .popup11 {
                    top: 10%;
                    left: 10%;
                    animation-delay: 1s;
                }
            }
        }
    }


    .faqSection {
        display: grid;
        justify-content: center;
        padding: $s10 $s1;

        .faqContainer {
            display: grid;
            max-width: calc($s1 * 100);
            text-align: left;
            
            .accordion {
                box-shadow: unset;
                border: 1px solid rgba(0,0,0,0);  

                &:hover{
                    cursor: pointer;
                    background-color: $appBackgroundColorOffWhite;
                    border: 1px solid $tertiaryColorOffset;  
                }
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    .platform {

        .middlePieceSection{
            row-gap: $s2;
            grid-template-columns: 1fr;
            padding: $s10 $s5;


            .piece{
                flex-wrap: wrap;
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .platform {
        .heroSection {
            grid-template-columns: 1fr;

            .leftSection {
                z-index: 1;
                justify-items: center;
                min-height: 50vh;
                text-align: center;
            }

            .rightSection {
                .heroImageContainer {
                    position: relative;
                    max-width: 100%;
                    left: unset;
                    bottom: -$s5;

                    img {
                        max-width: 100%;
                    }
                }
            }
        }

        .integrationSection {
            grid-template-columns: 1fr;
            padding: $s10 $s5;

        }


    }
}