@import "../../variables.scss";

.footer {
    display: grid;
    justify-items: center;
    flex-wrap: wrap;
    align-content: center;
    grid-row-gap: $s2;
    background: $appBackground;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    min-height: 250px;
    padding: 1rem $s1;

    .buttonContainer{
        display: flex;
        flex-wrap: wrap;
        gap: $s2;

        a{
            width: 300px;
            flex: 1 auto;

            button{
                width: 100%;
            }
        }
    }

    .socialLinkBox {
        display: flex;
        text-align: left;
        margin-top: 25px;

        svg {
            width: 24px;
            height: 24px;
            margin-right: 15px;
            color: $tertiaryColorWhiteOffSet;
        }
    }

    .lottieContainer{
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    a {
        z-index: 1;
    }

}

@media only screen and (max-width: 550px) {
    .footer {
        .socialLinkBox {
            img {
                width: 24px;
                height: 24px;
                margin-right: 5px;
            }
        }

        .lottieContainer{
            margin-top: unset;
        }
    }
}
