@import '../../variables.scss';


.WhatCanYouBuildSection {
    background-color: $alternateBackgroundColor;

}

.WhatCanYouBuild {

    display: grid;
    padding: 0 $s10 * 2;

    position: relative;

    &>h2 {
        margin: 0 0 $s10 0;
    }

    .example {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        text-align: left;
        gap: $s8;
        margin-bottom: $s10 * 3;

        section.left {
            padding-left: $s10;

            .header {
                position: relative;

                .usageNumber {

                    // position: absolute;
                    // top: -$s8;
                    // left: -$s4;
                    font-size: $bannerFontSize * 1;
                    opacity: 0.1;
                    color: $tertiaryColor;
                }
            }
        }

        p {
            max-width: 400px;
            color: $panelSecondaryTextColour;
        }

        span {
            display: flex;
            align-items: center;
            gap: $s1;
        }

        .learnMore {
            text-decoration: underline;
        }

        section.right {
            position: relative;

            img {
                max-width: 800px;
            }

            .helperImage {
                max-height: 150px;
            }
        }

    }
}






@media only screen and (max-width: 1750px) {
    .WhatCanYouBuild {
        padding: 0 $s10 * 1.5;

        .example {
            display: grid;
            grid-template-columns: 0.5fr 1fr;
            align-items: center;

            section.left {
                padding-left: 0;
            }

            section.right {
                img {
                    max-width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1350px) {
    .WhatCanYouBuild {
        padding: 0 $s10;

        .example {
            section.right {
                .helperImage {
                    max-width: 150px;
                }
            }
        }
    }
}




@media only screen and (max-width: 1000px) {


    .WhatCanYouBuild {
        padding: 0 $s5;
        text-align: left;

        &>h2 {
            margin: 0 0 $s2 0;
        }

        .example {
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            margin-top: $s4;
            margin-bottom: $s4;

            &:first-child {
                margin-top: 0;
            }

            section.left {
                padding-left: 0;
            }

            section.right {
                img {
                    max-width: 100%;
                }

                .helperImage {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .WhatCanYouBuild {
        padding: $s2;

        .example {
            margin-top: $s2;


            &:first-child {
                margin-top: 0;
            }
        }
    }
}