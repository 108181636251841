@import "../../variables.scss";

.contactUs {
    min-height: calc(100vh - $topBarHeight);
    background-color: $panelColour;
    color: $panelTextColour;
    display: grid;
    row-gap: $s3;
    padding: $topBarHeight 0;
    justify-content: center;

    h1 {
        color: $tertiaryColor;
        margin-bottom: $s1;
    }

    .optionContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc($s1 * 50), 1fr));
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        justify-content: center;
        justify-self: stretch;
        width: calc($s1 * 120);
        max-width: calc(100vw - $s2);

        h5 {
            margin: 1rem;
        }

        .option {
            box-shadow: 0 0 calc(#{$s1}/3) rgba(0, 0, 0, 0.15);
            border: 1px solid rgba(0, 0, 0, 0);
            padding: $s4 $s1;
            border-radius: calc($s1/4);
            background-color: $appBackgroundTextColor;

            &:hover{
                border: 1px solid $tertiaryColorOffset;
                background-color: $appBackgroundColorOffWhite;
            }
        }

    }
}

@media only screen and (max-width: 550px) {
    .contactUs {
        padding: $s3 0;
        padding-top: $topBarHeight;
    }
}