@import "../../../variables.scss";

.customerRelationships {
    padding-top: $topBarHeight;

    .clippedBackground {
        clip-path: circle(29.8% at 100% 0);
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $appBackgroundColorOffWhite;
    }

    .problemBox {
        color: white !important;
        padding: 1rem 25px;
        position: relative;

        .dotted-random-pattern {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 200px;
        }
    }

    .challengeContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 2em;
        justify-items: center;
        width: 60%;
        margin: 50px auto;
        position: relative;

        .background {
            position: absolute;
            bottom: -120px;
        }

        .challengeBox {
            width: 200px;
            height: min-content;
            padding: 20px;
            border-radius: 5px;
            z-index: 10;

            &.primary {
                background-color: $primaryColor;
                color: white;
            }

            &.secondary {
                background-color: $secondaryColor;
            }

            &.tertiary {
                background-color: $tertiaryColorLighten;
                color: white;
            }

            &.primaryG {
                background-color: $primaryColorGradientCompliment;
                color: white;
            }
        }
    }

    .solutionBox {
        padding: $s4;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $s1;
        justify-items: center;
        align-items: center;
        background-color: $panelColour;
        color: $panelTextColour;
        z-index: 1;
        position: relative;

        section.left {
            text-align: left;

            h1 {
                color: $tertiaryColorLighten;
            }
        }

        p {
            max-width: 800px;
            margin: 25px 0;
        }

        iframe {
            margin: 0 0 25px 0;
            width: 800px;
            max-width: 100%;
            height: 500px;
            border-radius: $s2;
            border: none;
            outline: none;
            z-index: 1;
        }

        section.right {
            position: relative;
            .dotted-pattern {
                position: absolute;
                right: 0;
                top: 40%;
                transform: scale(1.5);
            }
        }
    }

    button {
        position: relative;
    }
}

@media only screen and (max-width: 1120px) {
    .customerRelationships {
        .challengeContainer {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            width: 100%;
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
            margin-top: 30px;

            .background {
                display: none;
            }

            .challengeBox {
                margin: 0 !important;
            }
        }

        .solutionBox {
            padding: $s4;
            grid-template-columns: 1fr;
            gap: $s6;

            section.left {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 475px) {
    .customerRelationships {
        .challengeContainer {
            .challengeBox {
                width: 100%;
            }
        }
    }
}
