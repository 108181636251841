@import "../../variables.scss";

.home {
  min-height: 100vh;
  position: relative;
  // background: url('../../../public/images/Frame 2.svg');
  background-size: cover;
  padding-top: $topBarHeight;
  margin-bottom: $topBarHeight;

  .middleSection {
    display: grid;
    grid-template-columns: 0.85fr 1fr;
    align-content: center;
    position: relative;
    min-height: calc(100vh - $topBarHeight);
    padding: 0 100px;
    column-gap: $s2;

    .leftSection {
      display: grid;
      text-align: left;
      align-content: center;
      z-index: 2;
      row-gap: $s1;

      h1{
        margin-bottom: $s3;
      }

      p {
        max-width: calc($s1 * 65);
        margin: 0;
      }


      .buttonContainer {
        display: flex;
        flex-wrap: wrap;
        column-gap: $s2;

        a {
          width: fit-content;
          flex: 1 auto;

          .callToAction {
            margin-top: 1rem;
            width: 100%;
          }
        }
      }


    }



    .rightSection {
      animation-delay: 0.5s;
      display: grid;
      align-content: center;
      justify-content: end;

      video {
        width: 100%;
        max-width: 800px;
        min-height: 50vh;
        border-radius: $s4;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .home {
    .middleSection {
      padding: 50px 100px;
      grid-template-columns: 1fr 1fr;

      grid-row-gap: 2rem;

    }

  }
}

@media only screen and (max-width: 1350px) {
  .home {
    min-height: unset;

    .clippedBackground {
      display: none;
    }

    .middleSection {
      grid-template-columns: 1fr;
      justify-items: center;
      align-content: start;
      margin-top: 100px;
      padding: 0 25px;

      .leftSection {
        display: grid;
        text-align: center;
        justify-items: center;
      }

    }
  }
}


@media only screen and (max-width: 500px) {
  .home {
    .middleSection {
      align-content: start;

      .rightSection {
        video {
          object-fit: unset;
          min-height: unset;
        }
      }

    }
  }
}


@keyframes clipped-background-animation {
  0% {
    clip-path: polygon(73% 0, 100% 0, 100% 100%, 73% 100%);
  }

  100% {
    clip-path: polygon(61% 0, 100% 0, 100% 100%, 62% 100%);
  }
}