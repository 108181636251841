@import "../../variables.scss";

.topBar .menu {
    display: grid;
    position: fixed;
    top: 0;
    height: 100vh;
    right: -$sideBarWidth;
    width: $sideBarWidth;
    background-color: $alternateBackgroundColor;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    z-index: 1001;

    ul {
        list-style: none;
        display: grid;
        align-content: start;
        grid-row-gap: 10px;
        margin-top: 70px;

        li {
            font-weight: 600;
            text-align: left;
            a {
                text-decoration: none;
                color: $primaryTextColor !important;

                

                &:hover {
                    cursor: pointer;
                    color: $primaryColor;
                }


            }

           
        }
    }
}

.menuOpen {
    animation-name: menuOpen;
}

.menuClose {
    animation-name: menuClose;
}

@keyframes menuOpen {
    0% {
        right: -$sideBarWidth;
    }
    100% {
        right: 0;
    }
}

@keyframes menuClose {
    0% {
        right: 0;
    }
    100% {
        right: -$sideBarWidth;
    }
}
