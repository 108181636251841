@import "../../variables.scss";


.valuesSection {
    background-color: white;
    padding: $s5 0 $s10 0;

    h1{
        margin-bottom:$s8;
    }
}

.careers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: $panelTextColour;
    padding-top: $topBarHeight;

    row-gap: $s5;
    min-height: calc(100vh - $topBarHeight);
    
    h1 {
        grid-column-end: span 2;
        color: $tertiaryColor;
        margin-top: $s10;
    }



    .leftSection {
        display: grid;
        align-content: start;
        justify-content: center;
        position: relative;
        
        .careerImage{
            position: absolute;
            bottom: 0;
            max-width: 100%;
            z-index: 2;
            // transform: rotateY(180deg);


        }

    }

    .rightSection {
        background-color: $appBackgroundColorOffWhite;
        padding: 2rem;
        text-align: left;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        color: $panelTextColour;
        z-index: 2;
        margin-bottom: $s5;
    }
}

@media only screen and (max-width: 1420px) {
    .careers {
        grid-template-columns: 1fr;

        h1 {
            grid-column-end: unset;
        }

        .leftSection {
            min-height: 300px;
            display: none;

            .StrokePattern{
                display: none;
            }

            img{
                display: none;
            }
        }

    }
}


@media only screen and (max-width: 550px) {
    .careers {

        .leftSection {
            min-height: 200px;

        }

        .rightSection {
            padding: 1.25rem;
        }
    }
}