@import "../../variables.scss";

.domainFees {
    display: grid;
    justify-content: center;
    align-content: center;
    color: $secondaryTextColor;
    padding-top: calc($topBarHeight + $s5);
    row-gap: $s10;
    width: 100%;


    .topSection {
        display: grid;
        row-gap: $s2;

        .body{
            margin-bottom: $s3;
        }
    }


    .preferenceSwitch {
        display: flex;
        margin: 0 auto $s4 auto;

        div {
            padding: $s2;
            background-color: $appBackgroundColorOffWhiteTransparent;

            &:hover {
                cursor: pointer;
            }

            &.selected {
                background-color: $tertiaryColor;
                color: $appBackgroundTextColor;
            }

            &:first-child {
                border-radius: $s3 0 0 $s3;
            }

            &:last-child {
                border-radius: 0 $s3 $s3 0;
            }
        }
    }

    .plusUsageHeader {
        position: relative;
        margin: $s8 0;

        img {
            max-height: calc($s1 * 12);
            position: relative;
            left: $s1;
            top: $s5;
            transform: rotateZ(-20deg);
            mix-blend-mode: darken;
        }
    }

    h2 {
        color: $panelTextColour;

        &.header {
            font-weight: 800;
        }
    }

    .subtitle2 {
        margin-bottom: $s5;
        justify-self: center;
    }

    .levelContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
        width: min(90vw, 1500px);
        gap: 1.5rem;
        justify-self: stretch;
        row-gap: $s5;
        text-align: left;
        justify-items: center;
        position: relative;
        top: -$s7;



        .level {
            padding: $s3;
            display: grid;
            align-content: start;
            gap: $s5;

            .headerSection {
                border-bottom: 1px solid $tertiaryColorWhiteOffSet;
                border-radius: unset !important;

                .title {
                    display: flex;
                    gap: $s2;
                    font-size: calc($s1 * 3) !important;
                    justify-content: space-between;

                    .cost {
                        font-size: calc($s1 * 2) !important;
                    }
                }
            }

            svg {
                path {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .recommended {
            background-color: $tertiaryColorWhiteOffSet;
            // height: 110%;
            // margin-top: -20px;
            border: calc($s1/4) solid $tertiaryColor;
            border-radius: $s1;
            position: relative;

            .headerSection {
                border-bottom: 1px solid $tertiaryColorDarkOffSet;
                border-radius: unset !important;
            }

            &::before {
                content: 'Recommended';
                width: 100px;
                font-size: 13px;
                height: 15px;
                position: relative;
                top: -10px;
                font-weight: 800;
                font-family: $headerFont;
                background-color: $tertiaryColor;
                color: $appBackgroundTextColor;
                padding: $s1;
                border-radius: $s3;
            }
        }

        .level:not(.recommended) {
            background-color: $appBackgroundColorOffWhite;
            border: calc($s1/4) solid $tertiaryColorWhiteOffSet;
            border-radius: $s1;
            margin-top: $s2;

        }
    }

}


.headerSection {
    display: grid;
    grid-template-columns: 1.75fr 1fr 1fr;
    column-gap: $s2;
    row-gap: $s1;
    padding: $s2 0;
    border-radius: $s1;
    align-items: center;
}

.headerSection h3 {
    color: $tertiaryColorDarkTextColor;
    font-family: $headerFont;
    font-size: $subtitle2Large;
}

.bodySection {
    display: grid;
    row-gap: $s2;

    .featuresContainer {
        display: grid;
        row-gap: $s2;
    }

    .category {
        display: grid;
        grid-template-columns: 1.75fr 1fr 1fr;
        column-gap: $s3;
        row-gap: $s1;
        border-radius: $s1;
        padding: $s2 $s2;
        height: max-content;
        min-height: calc(#{$s1} * 8);
        align-items: center;
        background-color: adjust-color($color: $tertiaryColor, $alpha: -0.99);

        &.alternate {
            background-color: adjust-color($color: $tertiaryColor, $alpha: -0.95);
        }

    }
}


.usageSection {
    padding: $s10;
    background-color: $appBackgroundTextColor;
    display: grid;
    justify-content: center;
    text-align: left;

    .subtitle2 {
        max-width: calc($s1 * 150);
        color: $secondaryTextColor;
    }

    display: grid;
    row-gap: $s1;


    .headerSection {
        // background-color: $tertiaryColor;
    }

    .MuiAlert-message {
        text-align: left;

        span {
            max-width: 1400px;
            display: inline-block;
        }
    }

    .container {
        background-color: $appBackgroundColorOffWhite;
        border: calc($s1/4) solid $tertiaryColorWhiteOffSet;
        width: 1200px;
        max-width: 95vw;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        text-align: left;
        border-radius: 5px;
        color: $panelTextColour;
        z-index: 2;
        row-gap: $s1;
        position: relative;
        margin-top: $s5;
        padding: $s2;

        /* Tooltip container */
        .tooltip {
            position: relative;
            display: grid;
            align-items: center;
            color: $tertiaryColor;

            &:hover {
                cursor: pointer;
            }
        }

        /* Tooltip text */
        .tooltip .tooltiptext {
            visibility: hidden;
            max-width: calc(#{$s1} * 20);
            background-color: $tertiaryColor;
            color: #fff;
            text-align: center;
            padding: $s1;
            border-radius: 6px;
            margin-left: $s1;
            /* Position the tooltip text - see examples below! */
            position: absolute;
            z-index: 1;
        }

        /* Show the tooltip text when you mouse over the tooltip container */
        .tooltip:hover .tooltiptext {
            visibility: visible;
        }
    }
}


.enterpriseSection {
    padding: $s8;
    text-align: left;
    padding-left: $s10;
    display: grid;
    justify-content: center;
    position: relative;

    * {
        z-index: 1;
    }

    span{
        max-width: calc($s1 * 120);
    }

    .bodySection{
        display: flex;
        gap: $s10;
    }

}

@media only screen and (max-width: 768px) {

    .topSection{
        h2{
            margin: 0;
        }
    }



    .enterpriseSection{
        padding: $s8 $s2;
    }

    .domainFees {
        

        .preferenceSwitch{
            margin-bottom: $s2;
        }

        .plusUsageHeader {
            img {
                max-height: calc($s1 * 8);
                position: relative;
                left: $s1;
                top: $s2;
                transform: unset;
                mix-blend-mode: darken;
            }

            margin-bottom: 0;
        }

        .levelContainer {
            .recommended {
                height: unset;
                margin-top: unset;
            }
        }

        .container {
            .boxes {
                display: none;
            }

            .headerSection {
                .headerSmall {
                    font-size: $subinfoMedium !important;
                }
            }

            .bodySection {
                span {
                    font-size: $subinfoLarge !important;
                }
            }
        }
    }
}