@import '../../variables.scss';

.Features {
    padding: $s10 $s2;
    background-color: $alternateBackgroundColor;
    position: relative;
    color: $tertiaryColor;

    .featuresContainer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: $s4;
        padding: $s5 0;
        position: relative;
        z-index: 1;

        .feature {
            height: $s10 * 4;
            width: $s10 * 5;
            background-color: $appBackgroundTextColor;
            color: $panelTextColour;
            padding: $s4;
            border-radius: calc(#{$s1}/2);
            transition: all 0.25s;
            opacity: 0;
            border: 1px solid rgba(0, 0, 0, 0);


            svg {
                color: $primaryTextColor;
                background-color: $tertiaryColorLighten;
                width: $s8;
                height: $s8;
                padding: $s2;
                border-radius: $s1;
            }

            &:hover {
                transform: translateY(-$s1);
                cursor: pointer;
                background-color: $appBackgroundColorOffWhite;
                border: 1px solid $tertiaryColorOffset;
            }
        }
    }

    .dotted-random-pattern {
        position: absolute;
        right: 0;
        width: 300px;
        bottom: 0;
        opacity: 0.95;
        z-index: 0;
    }
}