@import '../../variables.scss';

.sectionContainer {
    padding: $s8 $s3;
    position: relative;
    overflow: hidden;


    &.EndlessPossibilitiesContainer{
        background-color: $appBackgroundColorOffWhite;
    }

    .dotted-random-pattern {
        width: 200px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
        opacity: 0.55;
    }



    &>h1 {
        margin: 0 0 $s10 0;
    }
}

.EndlessPossibilities {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: $s4;
    align-items: center;
    position: relative;
    z-index: 1;
    color: $panelTextColour;

    section.left {
        margin-top: $s2;
        display: grid;
        gap: $s4;
        justify-items: start;
        text-align: left;

        p {
            margin: 0 0 $s2 0;
            width: 500px;
            max-width: 90vw;
        }

        .widget-list {
            display: flex;
            gap: $s2;
            justify-content: center;
            flex-wrap: wrap;

            span {
                font-weight: 600;
                background-color: lighten($color: $tertiaryColor, $amount: 40);
                padding: $s1;
                border-radius: calc(#{$s1}/2);
                color: $primaryTextColor;
                transition: all 0.25s;
                display: flex;
                align-items: center;
                gap: $s1;

                &:hover {
                    cursor: pointer;
                    box-shadow: 0 0 2px rgba($color: #000000, $alpha: 0.5);
                }

                &[data-active=true] {
                    background-color: $tertiaryColor;
                }
            }


        }

        .learnMore {
            display: flex;
            align-items: center;
            gap: $s1;
            text-decoration: underline;
        }
    }

    section.right {
        min-height: 400px;
        display: flex;
        align-items: center;

        img {
            width: 600px;
            border-radius: $s2;
        }

        .helperImage {
            width: 250px;
        }

    }

}


@media screen and (max-width: 1200px) {
    .sectionContainer {
        h1 {
            color: $tertiaryColor;
            margin: 0 0 $s2 0;
        }
    }

    .EndlessPossibilities {
        text-align: center;

        section.left {
            justify-items: center;
            text-align: center;
        }

        section.right {
            img {
                width: 90vw;
            }
        }
    }
}