@import "../../variables.scss";

.becomeAKzenPartner {

  max-width: 100vw;
  position: relative;
  padding: 100px 0 0 0;

  .heroSection {
    display: grid;
    align-content: center;
    grid-template-columns: 1fr 1fr;
    min-height: calc(100vh - $topBarHeight);
    padding: 100px 100px 50px 100px;
    column-gap: $s2;

    .rightSection {
      display: grid;
      justify-items: end;
      align-content: center;
      position: relative;

      video {
        width: 100%;
        max-width: 800px;
        min-height: 50vh;
        border-radius: $s4;
        object-fit: cover;
      }
    }

    .leftSection {
      display: grid;
      justify-items: start;
      align-content: center;
      text-align: left;


      h1 {
        max-width: 800px;
        margin-bottom: $s3;
        
      }

      span {
        max-width: 650px;
      }

      .buttonContainer {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
      }

      .buttonContainer {
        display: flex;
        flex-wrap: wrap;
        column-gap: $s4;

        a {
          width: fit-content;
          flex: 1 auto;
          max-width: calc($s1 * 40);

          .callToAction {
            margin-top: 1rem;
            width: 100%;
          }
        }
      }
    }
  }


  .testimonialContainer {
    background-color: white;
    display: grid;
    align-content: center;
    padding: $s10 0;
  }

  .testimonial {
    margin-right: 100px;

    img {
      max-height: 200px;
    }
  }


  .comparisonSection {
    display: grid;
    justify-items: center;
    justify-content: center;
    align-content: center;
    grid-template-columns: repeat(auto-fit, minmax(768px, 1fr));
    gap: $s5;
    min-height: 75vh;
    padding: $s10;
    color: $secondaryTextColor;
    background-color: white;

    .leftSection {
      width: 100%;
      display: grid;
      align-content: start;
      align-items: center;
      row-gap: $s2;
      text-align: left;

      .genericSection {
        display: grid;
        gap: $s2;
        height: calc($s1 * 30);

        span {
          max-width: calc($s1 * 80);
        }
      }

      .example {
        margin-top: $s3;
        width: 100%;
        gap: $s2;
        display: flex;
        flex-wrap: wrap;
        transition: all 0.25s;
        padding: $s5;
        border-radius: $s5;
        align-items: center;
        box-shadow: 0 0 calc(#{$s1}/3) rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(0, 0, 0, 0);
        justify-content: space-between;

        &:hover {
          border: 1px solid $tertiaryColorOffset;
          background-color: $appBackgroundColorOffWhite;
          cursor: pointer;

        }

        .description {
          max-width: calc($s1 * 45);
        }


        .imageContainer {

          img {
            border-radius: $s2;
            background-color: $tertiaryColorLighten;
            object-fit: cover;
            max-width: calc($s1 * 40);
          }

        }

      }
    }

    .rightSection {
      width: 100%;
      display: grid;
      justify-content: end;
      align-items: center;
      align-content: start;
      row-gap: $s2;
      text-align: left;
      border-radius: $s10;

      .genericSection {
        display: grid;
        gap: $s2;
        height: calc($s1 * 30);

        span {
          max-width: calc($s1 * 80);
        }
      }

      .example {
        position: relative;
        border-radius: $s5;
        padding: $s5;
        transition: all 0.25s;
        box-shadow: 0 0 calc(#{$s1}/3) rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(0, 0, 0, 0);
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;


        &:hover {
          border: 1px solid $tertiaryColorOffset;
          background-color: $appBackgroundColorOffWhite;
          cursor: pointer;
        }

        span{
          max-width: calc($s1 * 100);
        }
      }

      .subtitle2 {
        margin-top: $s3;
      }


      video {
        border-radius: $s4;
        width: 100%;
        max-width: min(450px, 95vw);
        height: auto;
        justify-self: end;
        margin-left: $s3;
      }
    }
  }

}


@media only screen and (max-width: 1450px) {
  .becomeAKzenPartner {
    .heroSection {
      padding: 50px 100px;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 2rem;

    }

  }
}

@media only screen and (max-width: 1350px) {
  .becomeAKzenPartner {
    min-height: unset;

    .heroSection {
      grid-template-columns: 1fr;
      justify-items: center;
      margin-top: 100px;
      padding: 0 25px;

      .leftSection {
        display: grid;
        text-align: center;
        justify-items: center;
      }

    }

    .comparisonSection {
      margin-top: $s5;
    }
  }
}


@media only screen and (max-width: 600px) {
  .becomeAKzenPartner {

    .heroSection {
      .rightSection {
        video {
          object-fit: unset;
          min-height: unset;
        }
      }

    }
  }
}


@media only screen and (max-width: 1535px) {
  .becomeAKzenPartner {
    .comparisonSection {
      .leftSection {
        padding: $s2 !important;
        border-right: unset;
        padding-bottom: $s5 !important;

        .example {
          .heroImage {
            float: unset;
            margin-left: unset;
          }
        }
      }

      .rightSection {
        justify-content: start;
        padding: $s2 !important;
        margin-top: $s5;

        p {
          display: grid;
          row-gap: $s2;

          video {
            margin: 0;
            justify-self: stretch;
          }
        }

      }
    }

  }
}

@media only screen and (max-width: 1535px) {
  .becomeAKzenPartner {
    grid-template-columns: 1fr;

    .heroSection {
      align-content: start;
    }

    .comparisonSection {
      display: grid;
      justify-items: center;
      align-content: center;
      grid-template-columns: repeat(auto-fit, minmax(768px, 1fr));
      min-height: 100vh;
      padding: $s10 0;
      color: $secondaryTextColor;

      .leftSection {
        border-right: calc($s1 / 6) solid $appBackgroundColorOffWhiteTransparent;
        width: 100%;
        display: grid;
        align-content: start;
        align-items: center;
        row-gap: $s1;
        padding: $s5;
        text-align: left;
        max-width: 100vw;

        .example {


          p {
            display: grid;
            grid-template-columns: 1fr;
            margin: 0;
            row-gap: $s2;
          }
        }
      }

      .rightSection {
        border-right: calc($s1 / 6) solid $appBackgroundColorOffWhiteTransparent;
        width: 100%;
        display: grid;
        align-content: start;
        align-items: center;
        row-gap: $s1;
        padding: $s5;
        text-align: left;
        max-width: 100vw;

        .example {
          margin: $s1;

          p {
            display: grid;
            grid-template-columns: 1fr;
            margin: 0;
            row-gap: $s2;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .clippedBackground {
    display: none;
  }

  .becomeAKzenPartner {
    .leftSection {
      color: black;
    }



    .comparisonSection{

      .leftSection{
        .example{
          padding: $s2;
        }
      }

      .rightSection{
        .example{
          padding: $s2;
        }
      }
    }
  }
}