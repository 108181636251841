.BackgroundWithShapes{
    position: fixed;
    display: none;
    top:0;
    left:0;
    min-width: 100vw;
    min-height: 100vh;
    z-index: -1;
    opacity: 0.75;
    .ellipse1{
        position: absolute;
        left: -50px;
        top: 100px;
        height: 20vh;
    }

    .traingle1{
        position: absolute;
        right: 15%;
        top: 25%;
        height: 10vh; 
    }

    .ellipse2{
        position: absolute;
        right: -50px;
        bottom: 0px;
        height: 20vh;
    }
}





@media screen and (max-width: 550px) {
    .BackgroundWithShapes{
        display: none;
    }
}