@import "../../variables.scss";

.sectionContainer {
  background-color: $panelColour;

  &.Kzen8ForBusinessesSection {
    background-color: $appBackgroundColorOffWhite;
    padding: 0;
  }
}

.sizeChoice {
  width: 100%;
  display: grid;
  gap: $s5;
  padding: $s5 0;

  &.selected{
    gap: 0;
  }

  h1 {
    color: $tertiaryColor;
    transition: all 0.25s;
    &.small {
      height: 0;
      overflow: hidden;
      margin: 0;
    }
  }

  .sizeOptionContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    gap: $s10;
    row-gap: $s5;
    border-radius: $s1;

    .size {
      width: 425px;
      max-width: 90vw;
      align-items: center;
      justify-content: center;
      gap: $s2;
      display: flex;
      background-color: $panelColour;
      color: $panelTextColour;
      padding: $s4;
      transition: transform 0.25s;
      border-radius: $s2;

      &.small {
        h3 {
          font-size: $s2 !important;
        }

        img {
          max-height: 44px;
        }
      }

      &.active {
        border: $s1/4 solid $primaryColor;
      }

      &:hover {
        cursor: pointer;
        transform: translateY(-$s1);
      }

      img {
        max-height: 75px;
      }
    }
  }
}

.kzen8ForBusinesses {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: start;
  justify-items: center;
  align-items: center;
  background-color: $panelColour;
  color: $panelTextColour;
  padding:  $s5 $s2;

  .dotted-pattern {
    position: absolute;
    left: $s1;
    top: 40%;
    transform: scale(1.5);
  }

  .rightSectionSmallScreen {
    display: none;
    align-items: center;
    padding-bottom: 32px;

    h1 {
      color: $tertiaryColor;
      text-align: center;
    }

    .paragraph {
      max-width: 768px;
      width: 95%;
      display: grid;
      justify-items: center;
      gap: $s2;

      ul {
        text-align: left;
      }
    }
  }

  .leftSection {
    display: grid;
    align-content: center;
    position: relative;
    justify-items: center;
    max-width: 768px;
    border-radius: 100%;
    gap: $s1;
  }

  .rightSection {
    display: grid;
    text-align: left;
    align-content: center;
    justify-content: center;
    padding-left: 20px;

    h1 {
      color: $tertiaryColorLighten;
    }

    .paragraph {
      max-width: 650px;
      line-height: $s4;

      display: flex;
      flex-direction: column;
      gap: $s1;

      ul {
        padding-left: $s4;
      }
    }

    .callToAction {
      width: 250px;
    }
  }
}

@media only screen and (max-width: 1450px) {
  .kzen8ForBusinesses {
    grid-template-columns: 1fr;
    justify-items: center;
    justify-content: center;

    .leftSection {
      display: grid;
      align-content: center;
      position: relative;
      justify-items: center;
      max-width: unset;
      background-color: unset;
      margin-top: -100px;
      gap: $s1;
    }

    .rightSectionSmallScreen {
      display: grid;
      justify-items: center;
      gap: $s1;

      .paragraph {
        display: grid;
        justify-items: center;

        a {
          margin-top: $s2;
        }
      }
    }

    .rightSection {
      display: none;
    }
  }
}

@media only screen and (max-width: 968px) {
  .kzen8ForBusinesses {
    grid-template-columns: 1fr;
    justify-items: center;

    .dotted-pattern {
      display: none;
    }

    .leftSection {
      margin: 0 auto 50px auto;
      gap: $s1;
    }

    .rightSectionSmallScreen {
      margin-bottom: 25px;
      text-align: justify;

      .paragraph {
        text-align: justify;
      }
    }

    video {
      box-shadow: 0 0 1px rgba($color: #000000, $alpha: 0.25);
      width: 95%;
    }
  }
}

@media only screen and (max-width: 550px) {
  .kzen8ForBusinesses {
    .rightSectionSmallScreen {
      .paragraph {
      }
    }
  }
}
