@import "../../variables.scss";

.businessAssessment {
    display: grid;
    min-height: calc(100vh - $topBarHeight);
    padding-top: $topBarHeight;

    iframe{
        min-height: 100% !important;
        width: 100vw !important;
        border: none !important;

    }
}
