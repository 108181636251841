@import '../../variables.scss';

.GetStartedToday{
    background-color: $appBackgroundColorOffWhite;
    padding: $s1 * 15 $s2;
    display: grid;
    justify-items: center;
    gap: $s4;

    h1{
        margin: 0;
        color: $panelTextColour;
    }

    .buttonContainer{
        display: flex;
        flex-wrap: wrap;
        gap: $s4;

        a{
            width: 300px;
            flex: 1 auto;

            button{
                width: 100%;
            }
        }
    }
}