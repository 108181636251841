@import "../../variables.scss";

.topBar {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  height: $topBarHeight;
  position: absolute;
  top: 0;
  z-index: 9999;

  &.alternate a{
    color: white !important;
  }

  li {
    white-space: pre;
  }

  .sideMenu {
    li {
      white-space: unset;
    }
  }

  a path {
    fill: white !important;
  }



  .leftSection {
    width: 100%;
    display: grid;
    justify-items: start;
    align-items: center;
    width: 100%;

    img {
      height: 44px;
    }
  }

  .rightSection {
    display: grid;
    align-items: center;
    justify-self: end;
    width: 90%;
    max-width: 850px;
    justify-items: end;

    ul {
      list-style: none;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin: 0;
      max-width: 850px;

      li {
        display: flex;
        align-items: center;
        margin-left: $s6;

        .base-MenuButton-root {
          background: none;
          box-shadow: none;
          outline: none;
          border: none;
          font-weight: bold;
          font-family: inherit;
          font-size: inherit;
          color: inherit;
          display: flex;
          align-items: center;
          &:hover {
            cursor: pointer;
            color: $primaryColor;
          }

        }


      }

      a {
        text-decoration: none;
        font-weight: bold;
        display: flex;
        align-items: center;

        &:hover {
          color: $primaryColor;
        }

        button.btn {
          padding: calc(#{$s1}/2) $s2 !important;
          height: $s6 !important;
        }
      }
    }
  }

  .burgerMenuButton {
    position: relative;
    z-index: 1010;
    animation-name: topBarAnimation;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-delay: 2.5s;
    justify-self: end;

    &:hover {
      cursor: pointer;
    }

    display: none;
  }
}

@media only screen and (max-width: 1268px) {
  .topBar {
    .burgerMenuButton {
      display: block;
    }

    .rightSection {
      ul {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .topBar {

    .leftSection {
      img {
        height: 32px;
      }
    }
  }
}