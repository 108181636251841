@import "../../variables.scss";


.aboutUs {
    display: grid;
    justify-items: center;
    align-content: center;
    min-height: calc(100vh - $topBarHeight);
    overflow: hidden;
    padding: $topBarHeight 0 0 0;
    row-gap: $s5;
    position: relative;

    &>h1{
        margin-top: $s10;
    }

    p {
        width: 1000px;
        max-width: 95vw;
        text-align: left;

        img {
            float: right;
            width: 60vw;
            max-width: 500px;
            border-radius: $s5;
            margin: 0 $s5 $s2 $s5;
        }
    }

    .valuesSection {
        background-color: white;
        padding: $s5 0 $s10 0;
        display: grid;
        row-gap: $s5;
    }

    .testimonialContainer {
        display: grid;
        align-content: center;
    }

    .testimonial {
        margin-right: 100px;

        img {
            max-height: 200px;
        }
    }

    .meetTheTeamSection {
        display: grid;
        row-gap: $s5;

        .teamContainer {
            display: flex;
            justify-content: center;
            gap: $s2;
            flex-wrap: wrap;

            .member {
                width: 500px;
                max-width: 95vw;
                background-color: white;
                box-shadow: 0 0 1px rgba($color: #000000, $alpha: 0.25);
                border-radius: $s2;
                text-align: left;
                overflow: hidden;
                padding: $s2;

                h2 {
                    padding: $s1 0;
                    margin-bottom: 0;
                }
                .profilePicture {
                    min-height: 300px;
                    max-width: 100%;
                    background-color: white;
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }

                p {
                    max-height: 250px;
                    overflow-y: scroll;
                    max-width: 100%;
                    

                    scrollbar-color: rgb(238, 238, 238) white;
                    scrollbar-width: thin;
                }
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .aboutUs {
        p {
            display: grid;
            row-gap: $s2;
            img {
                float: unset;
                display: block;
                width: 100%;
                margin: 0;
                max-width: unset;
            }
        }
    }
}
