@import "./variables.scss";

html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

h1{
  margin: 0;
}

.tutorial-link {
  display: grid;
  justify-content: center;
  align-content: center;
  color: $panelTextColour !important;
  min-height: unset !important;
  gap: $s5;

  &.sectionContainer > h1 {
    margin: 0;
  }

  &.sectionContainer{
    .dotted-random-pattern{
      opacity: 0.1;
      left: 25vw;
      width: 600px;
    }
  }
}

.testimonial{
  margin-right: 100px;

  img{
    max-height: 200px;
  }
}

.sectionContainer {
  min-height: 50vh;
}

.primary-text-colour {
  color: $primaryTextColor;
}

.secondary-text-colour {
  color: $secondaryTextColor;
}

.app-background-text-colour {
  color: $appBackgroundTextColor !important;
}

.topBarBackground {
  height: 60px;
  position: absolute;
  // background-color: $appBackgroundColor;
}

//font colour
.primary-text {
  color: $primaryColor !important;
}

//font colour
.secondary-text {
  color: $secondaryColor !important;
}

//font colour
.tertiary-text {
  color: $tertiaryColor;
}

//font colour
.tertiary-light-text {
  color: $tertiaryColorLighten;
}

//font colour
.tertiary-dark-text {
  color: $tertiaryColorDarkTextColor !important;
}

.App {
  text-align: center;
  background: $alternateBackgroundColor;
  min-height: fit-content;
  color: $secondaryTextColor;
  max-width: 100%;

  * {
    box-sizing: border-box;
  }
}


.backgroundColourWave1 {
  position: absolute;
  top: 0;
  left: -100px;
  opacity: 0.1;
  z-index: 0;
}

.app-container {
  padding: 0 100px;
  position: relative;
}

.full-width-element {
  width: calc(var(--vw, 1vw));
  margin-left: -100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

//buttons
.btn {
  height: 64px !important;
  font-size: 18px;
  padding: $s4 !important;
  border: none;
  border-radius: 40px !important;
  font-weight: 600 !important;
  text-transform: unset !important;
  box-shadow: unset !important;
  transition: all 0.25s !important;
  transform: translateY(0);

  &:hover {
    cursor: pointer;
    transform: translateY(4px);
  }
}


.awsPartnerBadges{
  background-color: darken($color: $alternateBackgroundColor, $amount: 1);
  display: grid;
  align-content: center;
  padding: $s8 $s10;
  row-gap: $s5;
  .badgeContainer{
    display: flex;
    flex-wrap: wrap;
    gap: $s10;
    justify-content: center;
  }

}


.base-Popup-root{
  z-index: 10000;
  .base-Menu-listbox{
    display: grid;
    position: relative;
    list-style: none;
    background-color: $alternateBackgroundColor;
    border-radius: $s1;
    padding: $s3 $s1;
    row-gap: $s2;
    box-shadow: 0 0 calc($s1/4) rgba(0, 0, 0, 0.25);
    min-width: 200px;
    li{
      font-family: $headerFont;
      font-weight: bold;
      padding: 0 $s1;
      border-radius: calc($s1/2);

      &:hover{
        background-color: $tertiaryColorLighten;
        color: $appBackgroundTextColor;
        cursor: pointer;
      }

      a{
        &:hover{
          background-color: $tertiaryColorLighten;
          color: $appBackgroundTextColor;
          cursor: pointer;
        }
      }

      span,a{
        padding: $s2 0;
        display: block;
      }
    }
  }
}

.btn-primary {
  background-color: $primaryColor !important;
  color: white;

  &.outlined{
    background-color: transparent !important;
    border-color: $primaryColor !important;
    color: $primaryColor !important;
  }

  a {
    display: block;
    width: 100%;
    color: white !important;
  }

  &:hover{
    background-color: fade-out($color: $primaryColor, $amount: 0.1) !important;
  }
}

.btn-secondary {
  background-color: $secondaryColor;
  color: white;

  a {
    display: block;
    width: 100%;
    color: white !important;
  }
}

.btn-tertiary {
  background-color: $tertiaryColor !important;
  color: white;

  &.light{
    background-color: $tertiaryColorLighten !important;
  }

  a {
    display: block;
    width: 100%;
    color: white !important;
  }

  &:hover{
    background-color: fade-out($color: $tertiaryColor, $amount: 0.1) !important;
  }
}


.accent{
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, $tertiaryColorWhiteOffSet 50%);
  max-width: fit-content;
  max-height: fit-content;
}


a {
  text-decoration: none;
  color: $primaryTextColor;
}

.cardShadow {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

// typography

.bannerHeading {
  font-size: $bannerFontSize !important;
  font-family: $headerFont !important;
  font-weight: 800;
  line-height: 1.107em;
  color: $tertiaryColorDarkTextColor;
}

.bigHeading {
  font-size: $bigHeadingLarge !important;
  font-family: $headerFont !important;
  line-height: 1.107em;
  color: $tertiaryColorDarkTextColor;
}

.header {
  font-size: $headingLarge !important;
  font-family: $headerFont !important;
  line-height: 1.107em;
  color: $tertiaryColorDarkTextColor;
}

.no-margin {
  margin: 0;
}

.headerSmall {
  font-size: $headingSmall !important;
  font-family: $headerFont !important;
  line-height: 1.107em;
  color: $tertiaryColorDarkTextColor;
}

.subtitle1 {
  font-size: $subtitle1Large !important;
  font-family: $bodyFont !important;
  font-weight: 600 !important;
  line-height: 1.107em;
}

.subtitle2 {
  font-size: $subtitle2Large !important;
  font-family: $bodyFont !important;
  font-weight: 600 !important;
  line-height: 1.107em;
}

.body {
  font-size: $bodyLarge !important;
  font-family: $bodyFont !important;
  font-weight: 575 !important;
  line-height: 1.667em;
  color: $secondaryTextColor;
}

.bodyLight {
  font-size: $bodyLarge !important;
  font-family: $bodyFont !important;
  line-height: 1.667em;
}

.subinfo {
  font-size: $subinfoLarge !important;
  font-family: $bodyFont !important;
  line-height: 1.667em;
}

//animationClasses

.animated {
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  position: relative;
}

.animated-inverse {
  animation-direction: reverse;
}

.initially-hidden {
  opacity: 0;
}

.slideUpAnimation {
  animation-name: slideUpAnimation;
}

.slideDownAnimation {
  animation-name: slideDownAnimation;
}

.slideRightAnimation {
  animation-name: slideRightAnimation;
}

.slideLeftAnimation {
  animation-name: slideLeftAnimation;
}

.rollOutRightAnimation {
  animation-name: rollOutRightAnimation;
}

.floatUpAnimation {
  animation-name: floatUpAnimation;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 8s;
}

.floatDownAnimation {
  animation-name: floatDownAnimation;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 6s;
}

.scaleIn {
  animation-name: scaleInAnimation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: scale(0);
}

@for $var from 1 to 100 {
  $var3: $var - 0.75;
  $var2: $var - 0.5;

  .delay-#{$var}-quarter {
    animation-delay: #{$var3}s;
  }

  .delay-#{$var}-half {
    animation-delay: #{$var2}s;
  }

  .delay-#{$var}-10 {
    animation-delay: #{$var3}s;
  }

  .delay-#{$var}-9 {
    animation-delay: #{$var2}s;
  }

  .delay-#{$var}-8 {
    animation-delay: #{$var}s;
  }

  .delay-#{$var}-7 {
    animation-delay: #{$var3}s;
  }

  .delay-#{$var}-6 {
    animation-delay: #{$var2}s;
  }

  .delay-#{$var}-5 {
    animation-delay: #{$var}s;
  }

  .delay-#{$var}-4 {
    animation-delay: #{$var3}s;
  }

  .delay-#{$var}-3 {
    animation-delay: #{$var2}s;
  }

  .delay-#{$var}-2 {
    animation-delay: #{$var}s;
  }

  .delay-#{$var} {
    animation-delay: #{$var}s;
  }
}

//animations
@keyframes slideUpAnimation {
  0% {
    top: 50px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes slideDownAnimation {
  0% {
    top: -50px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes slideRightAnimation {
  0% {
    left: -50px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes slideLeftAnimation {
  0% {
    left: 50px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes rollOutRightAnimation {
  0% {
    left: 0;
    opacity: 1;
    display: block;
  }

  99% {
    left: 100px;
    opacity: 0;
    transform: rotateZ(120deg);
  }

  100% {
    left: 1000px;
    opacity: 0;
    transform: rotateZ(120deg);
  }
}

@keyframes strokeFillAnimation {
  0% {
    stroke-dasharray: scale(1);
  }

  50% {
    transform: scale(0.85);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes floatUpAnimation {
  0% {
    animation-iteration-count: infinite;
    top: 0;
  }

  50% {
    top: -50px;
  }

  100% {
    top: 0;
  }
}

@keyframes floatDownAnimation {
  0% {
    animation-iteration-count: infinite;
    top: 0;
  }

  50% {
    top: -50px;
  }

  100% {
    top: 0;
  }
}

@keyframes scaleInAnimation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 1250px) {

  //typography
  .bigHeading,
  .bannerHeading {
    font-size: $bigHeadingMedium !important;
  }

  .header {
    font-size: $headingMedium !important;
  }

  .subtitle1 {
    font-size: $subtitle1Medium !important;
  }

  .subtitle2 {
    font-size: $subtitle2Medium !important;
  }

  .body {
    font-size: $bodyMedium !important;
  }

  .subinfo {
    font-size: $subinfoMedium !important;
  }
}

@media only screen and (max-width: 1320px) {
  .app-container {
    padding: 0 50px;
  }

  .full-width-element {
    margin-left: -50px;
  }
}

@media only screen and (max-width: 550px) {

  //typography
  .bigHeading {
    font-size: $bigHeadingSmall !important;
  }

  .header {
    font-size: $headingSmall !important;
  }

  .subtitle1 {
    font-size: $subtitle1Small !important;
  }

  .subtitle2 {
    font-size: $subtitle2Small !important;
  }

  .body {
    font-size: $bodySmall !important;
  }

  .subinfo {
    font-size: $subinfoSmall !important;
  }

  .app-container {
    padding: 0 25px;
  }

  .full-width-element {
    margin-left: -25px;
  }
}