@import "../../variables.scss";

.loadingScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 10000;
  
    display: grid;
    justify-items: center;
    align-content: start;
    gap: $s5;
    color: $primaryTextColor;
    padding: Min(10%, 100px);
    overflow-y: scroll;

    *:not(.logo) {
        opacity: 0;
        animation-name: loadingScreenOpacity;
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        animation-delay: 1s;
        animation-fill-mode: forwards;
    }

    .logo {
        max-height: 75px;
        max-width: 50vw;
        height: auto;
        animation-name: loadingScreen;
        animation-duration: 1.5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }

    .optionContainer {
        display: flex;
        flex-wrap: wrap;
        gap: $s5;
        row-gap: $s3;
    }

    .dotted-random-pattern {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 150px;
        max-width: 25vw;
    }

    .option {
        height: 250px;
        width: 275px;
        max-height: 20vh;
        background-color: fade-out($color: $primaryColor, $amount: 0.1);
        display: grid;
        flex: 1 auto;
        justify-items: center;
        grid-template-rows: 75% 25%;
        justify-content: center;
        align-items: center;
        border-radius: $s2;
        color: $primaryTextColor;
        animation-name: loadingScreenOptions;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        padding: $s3;
        box-sizing: border-box;
        transition: 0.25s;
        gap: $s1;
        position: relative;
        overflow: hidden;

        .overlay {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $appBackgroundColorOffWhite;
            border-radius: $s2;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: -100%;
            transition: all 0.25s;
            color: $panelTextColour;
            padding: $s5;
            font-size: calc(#{$s1} * 1.75);
            // backdrop-filter: blur($s1 * 1.5);
        }

        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                animation-delay: #{$i/3}s;
            }
        }

        &:hover {
            cursor: pointer;
            background-color: fade-out($color: $primaryColor, $amount: 0);

            & {
                .overlay {
                    bottom: 0;
                }
            }
        }
    }

    * {
        z-index: 2;
    }

    .background {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        // background-color: white;
        height: 100vh;
        width: 50vw;
        img {
            height: auto;
            max-height: 900px;
            width: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}

@keyframes loadingScreen {
    0% {
        transform: scale(1.25) translateY(10vh);
        opacity: 0;
    }
    50% {
        transform: scale(1.5) translateY(10vh);
        opacity: 1;
    }
    100% {
        transform: scale(1) translateY(0);
    }
}

@keyframes loadingScreenOptions {
    0% {
        transform: scale(0.75);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes loadingScreenOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 500px) {
    .loadingScreen {
        .background {
            display: none;
        }

        .dotted-random-pattern {
            display: none;
        }

        .option {
            .overlay {
                display: none;
            }
        }
    }
}
